import { createSlice } from '@reduxjs/toolkit';

/**
 * Setting the status of the workflow
 * {
 * [jid]:{ //jid is the id of the transaction
 *    wfStatus: 100, //number
 *    jStatus: 1, //number
 *  }
 * }
 */
const INITIAL_STATE = {};

const slice = createSlice({
  name: 'wf',
  initialState: INITIAL_STATE,
  reducers: {
    setStatus(state, action) {
      const { jid, wfStatus, jStatus } = action.payload;
      return {
        ...state,
        [jid]: { wfStatus, jStatus }
      };
    },
    batchUpdateStatus(state, action) {
      const updates = action.payload; // Expecting an array of { jid, wfStatus, jStatus }
      Object.entries(updates).forEach(([jid, { wfStatus, jStatus }]) => {
        state[jid] = { wfStatus, jStatus };
      });
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const wfReducer = slice.reducer;

export const { setStatus, batchUpdateStatus, restore, clear } = slice.actions;
export default wfReducer;
