import React, { useMemo, useState, useEffect } from 'react';
import {
  Box,
  TextField,
  useTheme,
  MenuItem,
  FormControlLabel,
  Switch,
  Typography,
  FormHelperText,
  IconButton,
  InputAdornment,
  Tooltip,
  Divider
} from '@material-ui/core';
import FieldContainer from './FieldContainer';
import {
  AtcList,
  SingleDatePicker,
  TinTextField,
  ContactNumberTextField,
  DebounceTextField,
  Field,
  SrchSub,
  useConfirmDialog,
  SelectWithSearch
} from 'src/components';
import { PRICE_TYPES } from 'src/constants';
import { add, join, toUpper } from 'lodash';
import data_json from 'src/assets/dataJson/address_data.json';
import AddressResult from '../AdrressResult';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PasteData from 'src/components/PasteData/PasteData';
import { useNotif } from 'src/hooks';

import { validateAddress } from '../StracturedAddressData';

import MenuOpenIcon from '@material-ui/icons/MenuOpen';

const DEFAULT_TYPES_VALUE = {
  str: '',
  int: 0,
  json: {},
  boolean: false,
  date: ''
};

const SubAccForm = ({
  subTypeMeta = {},
  subAcc = {},
  currentSubVal = {},
  showAdvance = false,
  error = {},
  updateField,
  updateKVSField,
  updateSubParent,
  updateSubLink,
  addAtc,
  removeAtc,
  showContainer = true,
  hasChanges,
  showDeactivateToggle = false,
  pasteResult,
  allowSaveAddress = () => {}
}) => {
  const notify = useNotif();
  const theme = useTheme();
  const { prompt } = useConfirmDialog();
  const { pretty_flds = {}, req_flds = [], kvs = {} } = subTypeMeta;
  const flds = useMemo(() => {
    const meta_flds = subTypeMeta?.flds ?? [];

    if (Boolean(subTypeMeta?.ui_layout?.show_price_type))
      return [...meta_flds, 'invPriceType'];

    if (Boolean(subTypeMeta?.ui_layout?.show_imp_id))
      return [...meta_flds, 'importID'];

    return meta_flds;
  }, [subTypeMeta]);

  const fldAllowed = fld => flds.includes(fld);
  const fldRequired = fld => req_flds.includes(fld);

  const address_ui_mode = subTypeMeta?.ui_layout?.address_ui_mode;

  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedTownCity, setSelectedTownCity] = useState('');
  const [selectedBarangay, setSelectedBarangay] = useState('');
  const [inputAddress, setInputAddress] = useState(subAcc?.fullAddress || '');
  const [addressData, setAddressData] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [openPaste, setOpenPaste] = useState(false);
  const [pasteData, setPasteData] = useState('');

  function handleFillAddress(data) {
    updateField({
      target: { name: 'addressProvince', value: data.addProvince }
    });
    updateField({
      target: { name: 'addressCity', value: data.addMuniCities }
    });
    updateField({
      target: { name: 'addressBrgy', value: data.addBarangays }
    });
    updateField({
      target: { name: 'addressStreet', value: data.addStreet }
    });
    updateField({
      target: { name: 'addressZIP', value: data.addZIP }
    });
  }

  const provinces = [...new Set(data_json.map(item => item.stateProv))];

  const townCities = selectedProvince
    ? [
        ...new Set(
          data_json
            .filter(item => item.stateProv === selectedProvince)
            .map(item => item.cityMuni)
        )
      ]
    : [];
  const barangays = selectedTownCity
    ? [
        ...new Set(
          data_json
            .filter(
              item =>
                item.stateProv === selectedProvince &&
                item.cityMuni === selectedTownCity
            )
            .map(item => item.townBar)
        )
      ]
    : [];

  useEffect(() => {
    if (subAcc?.addressProvince) {
      setSelectedProvince(subAcc?.addressProvince);
    }
  }, [subAcc?.addressProvince]);

  useEffect(() => {
    if (subAcc?.addressCity) {
      setSelectedTownCity(subAcc?.addressCity);
    }
  }, [subAcc?.addressCity]);

  useEffect(() => {
    if (subAcc?.addressBrgy) {
      setSelectedBarangay(subAcc?.addressBrgy);
    }
  }, [subAcc?.addressBrgy]);

  function parseValue(type, value) {
    switch (type) {
      case 'boolean':
        if (typeof value === 'string') return value === '1';
        else return value;
      default:
        return value;
    }
  }

  function getKeyValue(key) {
    const keyValues = subAcc?.kvs || [];
    const savedKvs = keyValues.find(data => data.key === key);

    const defaultValue =
      kvs?.[key]?.defaultValue || DEFAULT_TYPES_VALUE?.[kvs[key].type];

    if (savedKvs?.value !== '' || subAcc?.ixSub)
      return parseValue(kvs[key].type, savedKvs?.value);
    else return defaultValue;
  }

  const handleInputAddress = e => {
    setInputAddress(e.target.value);
    setErrorMessage(true);
  };
  const handleInputAddresses = async () => {
    setAddressData(inputAddress);
    setErrorMessage(false);
  };

  const handleSubmitAddress = data => {
    handleFillAddress(data);
  };

  useEffect(() => {
    if (subAcc?.fullAddress) {
      handleInputAddresses();
    }
  }, [subAcc]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = e => {
    setOpenDialog(e);
  };

  const {
    isAddressIncomplete,
    isProvinceIncomplete,
    isCityIncomplete,
    isBrgyIncomplete,
    isProvValue,
    isCityValue,
    isBrgyValue
  } = validateAddress(
    subAcc,
    selectedProvince,
    selectedTownCity,
    selectedBarangay
  );

  useEffect(() => {
    if (isAddressIncomplete) {
      allowSaveAddress(true);
    } else {
      allowSaveAddress(false);
    }
  }, [isAddressIncomplete]);

  const handlePaste = async () => {
    try {
      if (!navigator.clipboard) {
        console.error('Clipboard API not supported');
        setOpenPaste(true);
        return;
      }
      const text = await navigator.clipboard.readText();
      setPasteData(text);
      setOpenPaste(true);
    } catch (error) {
      console.error('Failed to read clipboard:', error);
    }
  };

  const handleClosePaste = e => {
    setOpenPaste(e);
  };
  const handlePastedResult = data => {
    if (data && data.length > 0) {
      const fieldMap = {};

      data.forEach(({ field, value }) => {
        fieldMap[field] = value;
      });

      Object.entries(fieldMap).forEach(([name, value]) => {
        updateField({
          target: { name, value }
        });
      });

      if (fieldMap.fullAddress) {
        setInputAddress(fieldMap.fullAddress);
      }

      if (fieldMap.contactNo) {
        const last10Digits = fieldMap.contactNo
          .trim()
          .split('')
          .slice(-10)
          .join('');
        updateField({
          target: { name: 'contactNo', value: last10Digits }
        });
      }
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gridRowGap={theme.spacing(showContainer ? 4 : 2)}
      >
        {subTypeMeta?.ui_layout?.paste_details?.allow && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <Tooltip title="Paste" arrow>
              <span>
                <IconButton
                  size="small"
                  tabIndex={-1}
                  onClick={handlePaste}
                  color="primary"
                >
                  <FileCopyIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Divider />
          </div>
        )}
        {openPaste && (
          <PasteData
            onClose={handleClosePaste}
            pasteData={pasteData}
            resultData={handlePastedResult}
            subTypeMeta={subTypeMeta?.ui_layout?.paste_details}
          />
        )}

        {/* ID */}
        {Boolean(subAcc?.ixSub) && (
          <FieldContainer showContainer={showContainer} title="ID">
            <Box display="flex" justifyContent="space-between">
              <TextField
                fullWidth
                variant="outlined"
                disabled
                value={subAcc.ixSub}
              />

              {showDeactivateToggle && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={subAcc?.subStatus === 1}
                      onChange={({ target: { checked } }) => {
                        if (!hasChanges('subStatus', checked ? 1 : 0)) {
                          updateField({
                            target: {
                              name: 'subStatus',
                              value: checked ? 1 : 0
                            }
                          });
                          return;
                        }

                        prompt({
                          title: (
                            <Typography variant="h4">
                              {`Confirm  ${
                                checked ? 'Activation' : 'Deactivation'
                              }`}
                            </Typography>
                          ),
                          body: (
                            <Typography variant="body1">
                              Are you sure you want to{' '}
                              {checked ? 'activate' : 'deactivate'}{' '}
                              <strong> {subAcc.sSub} </strong>?
                            </Typography>
                          ),
                          onOk: () => {
                            updateField({
                              target: {
                                name: 'subStatus',
                                value: checked ? 1 : 0
                              }
                            });
                          },
                          okText: 'Confirm',
                          okProps: {
                            variant: 'contained',
                            color: 'primary'
                          },
                          cancelProps: {
                            color: 'primary'
                          }
                        });
                      }}
                    />
                  }
                  label="Active"
                  labelPlacement="start"
                />
              )}
            </Box>
          </FieldContainer>
        )}

        {fldAllowed('RefNo') && (
          <FieldContainer
            showContainer={showContainer}
            title={pretty_flds?.RefNo || 'Ref No.'}
          >
            <DebounceTextField
              label={pretty_flds?.RefNo || 'Ref No.'}
              variant="outlined"
              fullWidth
              name="RefNo"
              error={Boolean(error?.RefNo)}
              helperText={error?.RefNo || ''}
              onChange={updateField}
              value={subAcc?.RefNo || ''}
              InputLabelProps={{
                shrink: true
              }}
              required={fldRequired('RefNo')}
            />
          </FieldContainer>
        )}

        {/* Title */}
        {(subTypeMeta?.acc_title === 1 || subTypeMeta?.acc_title === 2) && (
          <FieldContainer
            showContainer={showContainer}
            title={pretty_flds?.sSub || 'Title'}
          >
            <DebounceTextField
              variant="outlined"
              label={pretty_flds?.sSub || 'Title'}
              fullWidth
              name="sSub"
              value={subAcc?.sSub || ''}
              onChange={updateField}
              error={Boolean(error?.sSub)}
              helperText={error?.sSub || ''}
              InputLabelProps={{
                shrink: true
              }}
              required={fldRequired('sSub')}
            />
          </FieldContainer>
        )}

        {/* Name */}
        <FieldContainer showContainer={showContainer} title="Name">
          {fldAllowed('lname') && (
            <DebounceTextField
              label={pretty_flds?.lname || 'Last Name'}
              variant="outlined"
              fullWidth
              name="lname"
              error={Boolean(error?.lname)}
              helperText={error?.lname || ''}
              onChange={updateField}
              value={subAcc?.lname || ''}
              InputLabelProps={{
                shrink: true
              }}
              required={fldRequired('lname')}
            />
          )}

          {fldAllowed('fname') && (
            <DebounceTextField
              label={pretty_flds?.fname || 'First Name'}
              variant="outlined"
              fullWidth
              name="fname"
              error={Boolean(error?.fname)}
              helperText={error?.fname || ''}
              onChange={updateField}
              value={subAcc?.fname || ''}
              InputLabelProps={{
                shrink: true
              }}
              required={fldRequired('fname')}
            />
          )}

          {fldAllowed('mname') && (
            <DebounceTextField
              label={pretty_flds?.mname || 'First Name'}
              variant="outlined"
              fullWidth
              name="mname"
              error={Boolean(error?.mname)}
              helperText={error?.mname || ''}
              onChange={updateField}
              value={subAcc?.mname || ''}
              InputLabelProps={{
                shrink: true
              }}
              required={fldRequired('mname')}
            />
          )}
          {(fldAllowed('lname') || fldAllowed('fname')) && (
            <Box display="flex" justifyContent="space-between" gridGap="1em">
              <DebounceTextField
                label="Prefix Name"
                variant="outlined"
                fullWidth
                name="pname"
                error={Boolean(error?.pname)}
                helperText={error?.pname || ''}
                onChange={updateField}
                value={subAcc?.pname || ''}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <DebounceTextField
                label="Suffix Name"
                variant="outlined"
                fullWidth
                name="sname"
                error={Boolean(error?.sname)}
                helperText={error?.sname || ''}
                onChange={updateField}
                value={subAcc?.sname || ''}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Box>
          )}
        </FieldContainer>

        {fldAllowed('taxRegName') && (
          <FieldContainer
            showContainer={showContainer}
            title={pretty_flds?.taxRegName || 'Registered Name'}
          >
            <DebounceTextField
              fullWidth
              variant="outlined"
              label={pretty_flds?.taxRegName || 'Registered Name'}
              onChange={updateField}
              error={Boolean(error?.taxRegName)}
              helperText={error?.taxRegName || ''}
              value={subAcc?.taxRegName || ''}
              name="taxRegName"
              InputLabelProps={{
                shrink: true
              }}
              required={fldRequired('taxRegName')}
            />
          </FieldContainer>
        )}

        {fldAllowed('birthDate') && (
          <FieldContainer
            showContainer={showContainer}
            title={pretty_flds?.birthDate || 'Birthday'}
          >
            <SingleDatePicker
              fullWidth
              onChange={updateField}
              error={Boolean(error?.birthDate)}
              helperText={error?.birthDate || ''}
              value={subAcc?.birthDate || ''}
              name="birthDate"
              label={pretty_flds?.birthDate || 'Birthday'}
              InputLabelProps={{
                shrink: true
              }}
              required={fldRequired('birthDate')}
            />
          </FieldContainer>
        )}

        <FieldContainer showContainer={showContainer} title="Contacts">
          {fldAllowed('contactNo') && (
            <DebounceTextField
              variant="outlined"
              label={pretty_flds?.contactNo || 'Phone No.'}
              fullWidth
              onChange={updateField}
              value={subAcc?.contactNo || ''}
              name="contactNo"
              error={Boolean(error?.contactNo)}
              helperText={error?.contactNo || ''}
              InputLabelProps={{
                shrink: true
              }}
              required={fldRequired('contactNo')}
            />
          )}

          {fldAllowed('email') && (
            <DebounceTextField
              type="email"
              label={pretty_flds?.email || 'E-mail'}
              variant="outlined"
              fullWidth
              error={Boolean(error?.email)}
              helperText={error?.email || ''}
              onChange={updateField}
              value={subAcc?.email || ''}
              name="email"
              InputLabelProps={{
                shrink: true
              }}
              required={fldRequired('email')}
            />
          )}
        </FieldContainer>

        <FieldContainer showContainer={showContainer} title="Address">
          {fldAllowed('address') && (
            <>
              <DebounceTextField
                variant="outlined"
                label={pretty_flds?.address || 'Address'}
                fullWidth
                onChange={updateField}
                value={subAcc?.address || ''}
                name="address"
                error={Boolean(error?.address)}
                helperText={error?.address || ''}
                InputLabelProps={{
                  shrink: true
                }}
                required={fldRequired('address')}
              ></DebounceTextField>
            </>
          )}

          {fldAllowed('address2') && (
            <DebounceTextField
              variant="outlined"
              label={pretty_flds?.address2 || 'Address 2'}
              fullWidth
              onChange={updateField}
              value={subAcc?.address2 || ''}
              name="address2"
              error={Boolean(error?.address2)}
              helperText={error?.address2 || ''}
              InputLabelProps={{
                shrink: true
              }}
              required={fldRequired('address2')}
            ></DebounceTextField>
          )}
        </FieldContainer>

        <FieldContainer showContainer={showContainer} title="Detailed Address">
          {address_ui_mode === 3 ? (
            <Box>
              <TextField
                variant="outlined"
                fullWidth
                label={'Paste Here'}
                value={inputAddress}
                onChange={handleInputAddress}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleInputAddresses();
                  }
                }}
                error={!!errorMessage}
                helperText={
                  errorMessage ? (
                    <FormHelperText error>
                      {'Please Press Enter to Submit'}
                    </FormHelperText>
                  ) : (
                    ''
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleOpenDialog} color="primary">
                        <MenuOpenIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <AddressResult
                onEntert={addressData}
                onsubmitData={handleSubmitAddress}
                openDlg={openDialog}
                closeDlg={handleCloseDialog}
              />
            </Box>
          ) : null}
          {fldAllowed('addressProvince') && (
            <Box display="flex" alignItems="center">
              {address_ui_mode !== 2 ? (
                <DebounceTextField
                  label={pretty_flds?.addressProvince || 'Province'}
                  variant="outlined"
                  fullWidth
                  onChange={e => {
                    updateField(e);
                    setSelectedProvince(e.target.value);
                  }}
                  error={Boolean(add.addressProvince) || isProvinceIncomplete}
                  value={isProvValue || ''}
                  name="addressProvince"
                  helperText={
                    error?.addressProvince ||
                    (isProvinceIncomplete && 'Required')
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              ) : (
                <Box style={{ width: '100%' }}>
                  <SelectWithSearch
                    label={pretty_flds?.addressProvince || 'Province'}
                    options={provinces.map(province => ({
                      value: province,
                      description: province
                    }))}
                    searchKeys={['description']}
                    onChange={e => {
                      const newValue = e.target.value;
                      setSelectedProvince(newValue);
                      updateField({
                        target: { name: 'addressProvince', value: newValue }
                      });
                    }}
                    value={selectedProvince || ''}
                    name="addressProvince"
                    error={Boolean(error?.addressProvince)}
                    helperText={error?.addressProvince || ''}
                  />
                </Box>
              )}
            </Box>
          )}

          {fldAllowed('addressCity') && (
            <Box display="flex" alignItems="center">
              {address_ui_mode !== 2 ? (
                <DebounceTextField
                  label={pretty_flds?.addressCity || 'City'}
                  variant="outlined"
                  fullWidth
                  onChange={e => {
                    updateField(e);
                    setSelectedTownCity(e.target.value);
                  }}
                  value={isCityValue || ''}
                  name="addressCity"
                  error={Boolean(error?.addressCity) || isCityIncomplete}
                  helperText={
                    error?.addressCity || (isCityIncomplete && 'Required')
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              ) : (
                <Box style={{ width: '100%' }}>
                  <SelectWithSearch
                    label={pretty_flds?.addressCity || 'City'}
                    options={townCities.map(townCity => ({
                      value: townCity,
                      description: townCity
                    }))}
                    searchKeys={['description']}
                    onChange={e => {
                      const newValue = e.target.value;
                      setSelectedTownCity(newValue);
                      updateField({
                        target: { name: 'addressCity', value: newValue }
                      });
                    }}
                    value={selectedTownCity || ''}
                    name="addressCity"
                    error={Boolean(error?.addressCity)}
                    helperText={error?.addressCity || ''}
                  />
                </Box>
              )}
            </Box>
          )}

          {fldAllowed('addressBrgy') && (
            <Box display="flex" alignItems="center">
              {address_ui_mode !== 2 ? (
                <DebounceTextField
                  label={pretty_flds?.addressBrgy || 'Barangay'}
                  variant="outlined"
                  fullWidth
                  onChange={e => {
                    updateField(e);
                    setSelectedBarangay(e.target.value);
                  }}
                  value={isBrgyValue || ''}
                  name="addressBrgy"
                  error={Boolean(error?.addressBrgy) || isBrgyIncomplete}
                  helperText={
                    error?.addressBrgy || (isBrgyIncomplete && 'Required')
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              ) : (
                <Box style={{ width: '100%' }}>
                  <SelectWithSearch
                    label={pretty_flds?.addressBrgy || 'Barangay'}
                    options={barangays.map(barangay => ({
                      value: barangay,
                      description: barangay
                    }))}
                    searchKeys={['description']}
                    onChange={e => {
                      const newValue = e.target.value;
                      setSelectedBarangay(newValue);
                      updateField({
                        target: { name: 'addressBrgy', value: newValue }
                      });
                    }}
                    value={selectedBarangay || ''}
                    name="addressBrgy"
                    error={Boolean(error?.addressBrgy)}
                    helperText={error?.addressBrgy || ''}
                  />
                </Box>
              )}
            </Box>
          )}

          {/* Street Field */}
          {fldAllowed('addressStreet') && (
            <DebounceTextField
              label={pretty_flds?.addressStreet || 'Street'}
              variant="outlined"
              fullWidth
              onChange={updateField}
              value={subAcc?.addressStreet || ''}
              name="addressStreet"
              error={Boolean(error?.addressStreet)}
              helperText={error?.addressStreet || ''}
              InputLabelProps={{
                shrink: true
              }}
            />
          )}

          {/* ZIP Field */}
          {fldAllowed('addressZIP') && (
            <DebounceTextField
              label={pretty_flds?.addressZIP || 'ZIP'}
              variant="outlined"
              fullWidth
              onChange={updateField}
              value={subAcc?.addressZIP || ''}
              name="addressZIP"
              error={Boolean(error?.addressZIP)}
              helperText={error?.addressZIP || ''}
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
        </FieldContainer>

        <FieldContainer
          showContainer={showContainer}
          title="Linked Sub-Accounts"
        >
          {Boolean(subTypeMeta?.parent?.ixSubType) && (
            <SrchSub
              ixSubType={subTypeMeta?.parent?.ixSubType}
              sSubType={`${subTypeMeta?.parent?.sSubType} (Parent)`}
              selectedSub={subAcc?.sSubParent || ''}
              handleSubSelectRoot={updateSubParent}
              error={Boolean(error?.ixSubParent)}
              errorMessage={error?.ixSubParent || ''}
              required={fldRequired('ixSubParent')}
            />
          )}
          {Object.entries(subTypeMeta?.subLink || {}).map(
            ([key, { ixSubType, ...rest }]) => {
              if (ixSubType === 0) return null;

              return (
                <SrchSub
                  key={'ixSubLink' + key}
                  ixSubType={ixSubType}
                  selectedSub={subAcc?.['sSubLink' + key] || ''}
                  handleSubSelectRoot={({ ixSub, sSub }) =>
                    updateSubLink('ixSubLink' + key, 'sSubLink' + key, {
                      ixSub,
                      sSub
                    })
                  }
                  error={Boolean(error['ixSubLink' + key])}
                  errorMessage={error?.['ixSubLink' + key] || ''}
                  required={fldRequired('ixSubLink' + key)}
                  {...rest}
                />
              );
            }
          )}
        </FieldContainer>

        {fldAllowed('TIN') && (
          <FieldContainer
            showContainer={showContainer}
            title={pretty_flds?.TIN || 'TIN'}
          >
            <TinTextField
              variant="outlined"
              fullWidth
              onChange={updateField}
              value={subAcc?.TIN || ''}
              name="TIN"
              label="TIN"
              error={Boolean(error?.TIN)}
              helperText={error?.TIN || ''}
              InputLabelProps={{
                shrink: true
              }}
              required={fldRequired(`TIN`)}
            />
          </FieldContainer>
        )}

        {fldAllowed('customerType') && (
          <FieldContainer showContainer={showContainer} title="Customer Type">
            <TextField
              variant="outlined"
              fullWidth
              onChange={updateField}
              value={subAcc?.customerType || 0}
              name="customerType"
              error={Boolean(error?.customerType)}
              helperText={error?.customerType || ''}
              InputLabelProps={{
                shrink: true
              }}
              select
            >
              {Object.entries(subTypeMeta?.customerType_choices).map(
                ([key, value]) => (
                  <MenuItem value={key} key={key}>
                    {value}
                  </MenuItem>
                )
              )}
            </TextField>
          </FieldContainer>
        )}

        {fldAllowed('vatType') && (
          <FieldContainer showContainer={showContainer} title="VAT type">
            <TextField
              label=""
              variant="outlined"
              fullWidth
              onChange={updateField}
              value={
                subAcc?.vatType === '' ||
                subAcc?.vatType === undefined ||
                subAcc?.vatType === null
                  ? 1
                  : subAcc.vatType
              }
              name="vatType"
              error={Boolean(error?.vatType)}
              helperText={error?.vatType || ''}
              InputLabelProps={{
                shrink: true
              }}
              select
            >
              {Object.entries(subTypeMeta?.vatType_choices).map(
                ([key, value]) => (
                  <MenuItem value={parseInt(key)} key={key}>
                    {value}
                  </MenuItem>
                )
              )}
            </TextField>
          </FieldContainer>
        )}

        {fldAllowed('invPriceType') && (
          <FieldContainer showContainer={showContainer} title="Price Type">
            <TextField
              variant="outlined"
              fullWidth
              onChange={updateField}
              value={subAcc?.invPriceType || 0}
              name="invPriceType"
              error={Boolean(error?.invPriceType)}
              helperText={error?.invPriceType || ''}
              InputLabelProps={{
                shrink: true
              }}
              select
            >
              {PRICE_TYPES.map(({ caption, value }) => (
                <MenuItem value={value} key={value}>
                  {caption}
                </MenuItem>
              ))}
            </TextField>
          </FieldContainer>
        )}

        {fldAllowed('atc_list') && (
          <FieldContainer showContainer={showContainer} title="Default ATC">
            <AtcList
              selectedList={subAcc?.atc_list || []}
              onAddAtc={addAtc}
              onRemoveAtc={removeAtc}
            />
          </FieldContainer>
        )}

        {Object.entries(kvs)
          .filter(([, props]) => !props?.hidden)
          .sort((a, b) => (a[1]?.sort || 0) - (b[1]?.sort || 0))
          .map(([key, props]) => {
            const data = {
              ...props,
              value: getKeyValue(key),
              fld: key,
              error: Boolean(error?.kvs?.[key] || ''),
              errorMessage: error?.kvs?.[key] || ''
            };

            if (props?.ui_component === 'logs') {
              data.currentValue = currentSubVal;
            }

            return (
              <FieldContainer
                showContainer={showContainer}
                title={props?.caption || ''}
                key={key}
              >
                <Field data={data} onChangeField={updateKVSField} />
              </FieldContainer>
            );
          })}

        {fldAllowed('rate') && (
          <FieldContainer showContainer={showContainer} title={'Rate'}>
            <Field
              data={{
                ui_component: 'number',
                value: subAcc?.rate || 0,
                fld: 'rate'
              }}
              onChangeField={updateField}
            />
          </FieldContainer>
        )}

        {fldAllowed('importID') && (
          <FieldContainer showContainer={showContainer} title="Import ID">
            <TextField
              variant="outlined"
              fullWidth
              onChange={updateField}
              value={subAcc?.importID || ''}
              name="importID"
              error={Boolean(error?.importID)}
              helperText={error?.importID || ''}
              InputLabelProps={{
                shrink: true
              }}
              label="Import ID"
            />
          </FieldContainer>
        )}

        {showAdvance && (
          <>
            {fldAllowed('uuid') && (
              <FieldContainer
                showContainer={showContainer}
                title={pretty_flds?.UUID || 'UUID'}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  label="UUID"
                  onChange={updateField}
                  value={subAcc?.uuid || ''}
                  name="uuid"
                  error={Boolean(error?.uuid)}
                  helperText={error?.uuid || ''}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </FieldContainer>
            )}
            {fldAllowed('lSort') && (
              <FieldContainer
                showContainer={showContainer}
                title={pretty_flds?.lSort || 'Order'}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Order"
                  onChange={updateField}
                  value={subAcc?.lSort || ''}
                  name="lSort"
                  error={Boolean(error?.lSort)}
                  helperText={error?.lSort || ''}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </FieldContainer>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default SubAccForm;
