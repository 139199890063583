import {
  Button,
  DialogContent,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  TextField,
  Divider,
  Box
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useNotif } from 'src/hooks';
import { Alert, AlertTitle } from '@material-ui/lab';

const PasteData = ({ pasteData, onClose, resultData, subTypeMeta }) => {
  const [textField, setTextField] = useState('');
  const notify = useNotif();

  useEffect(() => {
    if (pasteData) {
      try {
        if (!pasteData) {
          notify.error('Clipboard is empty');
          return;
        }
        const parsed = pasteData
          .replace(/\r\n/g, '\n')
          .split('\n')
          .map(line => line.trim());
        setTextField(parsed.join('\n'));

        notify.success('Successfully pasted');
      } catch (err) {
        console.error('Clipboard read error:', err);
        notify.error('Failed to paste. Check clipboard permissions.');
      }
    }
  }, [pasteData]);

  const handleTextFieldChange = e => {
    setTextField(e.target.value);
  };

  const formattedDatasub = parsed => {
    const mapping = subTypeMeta?.mapping || {};
    const pretty_flds = subTypeMeta?.pretty_flds || {};
    const formattedData = Object.entries(mapping)
      .map(([index, fieldKey]) => ({
        id: Number(index) + 1,
        field: fieldKey,
        label: pretty_flds[fieldKey] || fieldKey,
        value: parsed[index] || ''
      }))
      .sort((a, b) => a.id - b.id);
    resultData(formattedData);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (event.ctrlKey) {
        return;
      } else {
        event.preventDefault();
        const parsed = textField.split('\n').map(line => line.trim());
        formattedDatasub(parsed);
        notify.success('Proceeding with Data');
        onClose(false);
      }
    }
  };

  const handleProceed = () => {
    const parsed = textField.split('\n').map(line => line.trim());
    formattedDatasub(parsed);
    notify.success('Proceeding with Data');
    onClose(false);
  };

  return (
    <Dialog open={true} onClose={() => onClose(false)} maxWidth="sm" fullWidth>
      <DialogContent>
        <Alert severity="info">
          <AlertTitle>
            Please follow the order of details below, each separated by a new
            line:
          </AlertTitle>
          {Object.entries(subTypeMeta?.mapping)
            .sort(([indexA], [indexB]) => indexA - indexB)
            .map(([index, fieldKey]) => (
              <Typography
                variant="caption"
                display="block"
                key={index}
                sx={{ color: 'black', fontWeight: 'bold' }}
              >
                {`* ${subTypeMeta?.pretty_flds[fieldKey] || fieldKey}`}
              </Typography>
            ))}
        </Alert>

        <TextField
          label="Paste Data"
          multiline
          rows={8}
          variant="outlined"
          fullWidth
          margin="normal"
          value={textField}
          onChange={handleTextFieldChange}
          onKeyDown={handleKeyDown}
        />
        <Typography variant="caption" display="block" gutterBottom="small">
          Press Enter or Proceed to parse, (Shift + Enter to Submmit).
        </Typography>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button onClick={() => onClose(false)} size="small">
          Cancel
        </Button>
        <Button
          onClick={handleProceed}
          variant="contained"
          color="primary"
          size="small"
        >
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasteData;
