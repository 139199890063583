import { useSnackbar } from 'notistack';
import React from 'react';
import { Close } from '@material-ui/icons';
import { IconButton, Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

/**
 *
 * @returns {notify}
 */
const useNotif = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const baseURL = window.location.origin;

  const success = (text, options = {}) => {
    enqueueSnackbar(text, { variant: 'success', ...options });
  };

  const info = (text, options = {}) => {
    enqueueSnackbar(text, { variant: 'info', ...options });
  };

  const warning = (text, options = {}) => {
    enqueueSnackbar(text, { variant: 'warning', ...options });
  };

  const error = (text, options = {}) => {
    enqueueSnackbar(text, { variant: 'error', ...options });
  };
  const custom = (text, variant, linkText, path, options = {}) => {
    enqueueSnackbar(
      <>
        {text}

        <Link to={path} target="_blank" style={{ color: 'inherit' }}>
          {linkText}
        </Link>
      </>,
      {
        variant: variant,
        action: key => (
          <IconButton
            onClick={e => {
              e.stopPropagation(); // Prevent navigating when clicking close
              closeSnackbar(key);
            }}
          >
            <Close style={{ color: 'white' }} />
          </IconButton>
        ),

        ...options // Allows custom parameters
      }
    );
  };

  return { success, info, warning, error, custom };
};

export default useNotif;
