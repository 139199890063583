import React, { createContext, useState, useEffect, useMemo } from 'react';
import { io } from 'socket.io-client';
import { useNotif } from 'src/hooks';
import { useSelector, useDispatch } from 'react-redux';
import { setStatus } from 'src/redux/slices/wf';
import { logout } from 'src/redux/slices/auth';
import { useNavigate } from 'react-router';

// Create the context
const SocketContext = createContext(null);

// Create the provider
const SocketProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken, current_user, ixBiz } = useSelector(({ auth }) => auth);
  const [socket, setSocket] = useState(null);
  const { success, info, error, custom } = useNotif();
  const { frontEnd } = useSelector(({ bizMeta }) => bizMeta);
  const isSocketAllowed = frontEnd?.socket?.allow ?? false;

  const socketURL = 'https://demo.ulap.biz/';
  useEffect(() => {
    if (!isSocketAllowed) {
      return;
    }
    if (!current_user.username && !userToken) {
      if (socket) {
        console.log('Disconnnecting');
        socket.disconnect(); // 🔹 Disconnect if state becomes invalid
        setSocket(null);
      }
      return;
    }

    let testSocket;

    if (!socket) {
      // Check if another tab already has a socket ID
      // const storedSocketId = localStorage.getItem('socketId');
      testSocket = io(socketURL, {
        query: { token: userToken, username: current_user.username },
        extraHeaders: {
          Authorization: userToken,
          username: current_user.username
        }
      });
      testSocket.on('connect', () => {
        console.log('Connected');
      });
      testSocket.on('command', data => {
        commandLogic(data);
      });
      testSocket.on('message', data => {
        console.log(data);
        // success(data);
      });
      testSocket.on('error', data => {
        error(data);
      });
      testSocket.on('disconnect', () => {
        console.log('Disconnected');
        error('Disconnected');
      });
      setSocket(testSocket);

      if (ixBiz && current_user && isSocketAllowed) {
        testSocket.emit('join_room', {
          ixBiz: ixBiz,
          room: 'general',
          username: current_user.username
        });
      }
    }

    return () => {
      if (testSocket) {
        testSocket.off('command');
        testSocket.off('message');
        testSocket.off('error');
        testSocket.off('disconnect');
        testSocket.disconnect();
      }
    };
  }, [userToken, isSocketAllowed, current_user]);

  function commandLogic(data) {
    const { linkText, path } = data;
    if (data.type === 'WF_Update') {
      const options = {
        persist: true,
        hideIconVariant: false,
        maxSnack: 3
      };
      if (data.username != current_user.username) {
        custom(data.message, 'info', linkText, path, options);
      }

      dispatch(
        setStatus({
          jid: data.jid,
          wfStatus: data.wfStatus,
          jStatus: data.jStatus
        })
      );
    }
    if (data.type === 'Assigned_Update') {
      if (
        data.userList.includes(current_user.username) &&
        data.username != current_user.username
      ) {
        const options = {
          persist: true,
          hideIconVariant: false,
          maxSnack: 3
        };
        custom(data.message, 'info', linkText, path, options);
      }
    }
    if (data.type === 'Logout') {
      if (data.targetUser == current_user.username) {
        dispatch(logout());
        navigate('/app/login');
      }
    }
  }
  // Function to join a room
  const joinRoom = (biz, username) => {
    if (socket) {
      if (biz && username && isSocketAllowed) {
        socket.emit('join_room', {
          ixBiz: ixBiz,
          room: 'general',
          username: username
        });
      }
    }
  };

  // Function to leave a room
  const leaveRoom = room => {
    if (room && socket) {
      socket.emit('leave_room', { room });
    }
  };

  const basicInfo = {
    username: current_user.username,
    room: 'general'
  };
  // Functions to send command/data
  // data = object
  // required ixBiz, username, room
  const sendMessage = data => {
    if (socket) {
      const mergedData = { ixBiz, ...basicInfo, ...data };
      socket.emit('send_data', mergedData);
    }
  };
  const sendCommand = data => {
    if (socket) {
      const mergedData = { ixBiz, ...basicInfo, ...data };
      socket.emit('send_command', mergedData);
    }
  };

  function Disconnect() {
    if (socket) {
      socket.disconnect();
    }
  }
  function RemoteLogout() {
    if (socket) {
      const data = {
        type: 'Logout',
        targetUser: current_user.username
      };
      const mergedData = { ixBiz, ...basicInfo, ...data };
      socket.emit('send_command', mergedData);
    }
  }
  return (
    <SocketContext.Provider
      value={{
        socket,
        joinRoom,
        leaveRoom,
        sendMessage,
        sendCommand,
        Disconnect,
        RemoteLogout
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
export { SocketProvider };
export default SocketContext;
