import { useState } from 'react';

const useAddress = ({ formattedData, onEntert, handleSetDelimiter }) => {
  let allCapsText = onEntert.toUpperCase();

  let rawWords = allCapsText
    .split(String(handleSetDelimiter) || ',')
    .map(word => word.trim());

  function scanAndRemoveNumbers(arr) {
    const numberRegex = /\b\d{4,}\b/;
    const ZIP = [];

    arr = arr.filter(item => {
      if (numberRegex.test(item)) {
        ZIP.push(item.match(numberRegex)[0]);
        return false;
      }
      return true;
    });

    return { updatedArray: arr, ZIP };
  }

  const { updatedArray, ZIP } = scanAndRemoveNumbers(rawWords);
  let delWords = updatedArray;

  const formatWithDashes = words =>
    words.map(word => word.replace(/\s+/g, '-'));
  const restoreWhiteSpace = words => words.map(word => word.replace(/-/g, ' '));

  const scanAndCompare = (words, list) => {
    const matchedWords = [];
    let remainingWords = [...words].reverse();

    for (let i = 0; i < remainingWords.length; i++) {
      const word = remainingWords[i];
      if (list.includes(word.toUpperCase())) {
        matchedWords.push(word);
        remainingWords = remainingWords.slice(i + 1);
        break;
      }
    }

    if (matchedWords.length === 0 && remainingWords.length > 0) {
      const dashedWords = formatWithDashes(remainingWords);
      for (let i = 0; i < dashedWords.length; i++) {
        const word = dashedWords[i];
        if (list.includes(word.toUpperCase())) {
          matchedWords.push(word);
          remainingWords = dashedWords.slice(i + 1);
          break;
        }
      }
    }

    const cleanedRemainingWords = restoreWhiteSpace(remainingWords.reverse());

    return { matchedWords, remainingWords: cleanedRemainingWords };
  };

  const provinceList = formattedData.map(prov => prov.province);

  const {
    matchedWords: matchedProvinces,
    remainingWords: remainingAfterProvince
  } = scanAndCompare(delWords, provinceList);
  delWords = remainingAfterProvince;

  const provIndex = formattedData.findIndex(
    prov => prov.province === `${matchedProvinces}`
  );

  const cities = provIndex !== -1 ? formattedData[provIndex].cities : [];
  const townCities = cities.map(city => city.city);

  const {
    matchedWords: matchedCities,
    remainingWords: remainingAfterCities
  } = scanAndCompare(delWords, townCities);
  delWords = remainingAfterCities;

  const cityIndex = cities.findIndex(city => city.city === `${matchedCities}`);
  const cityBarangays = cityIndex !== -1 ? cities[cityIndex].barangays : [];

  const barangays = cityBarangays.map(barangay => barangay.barangay);

  const evaluateBarangay = (remainingWords, barangays) => {
    const formatWithDashes = word => word.replace(/\s+/g, '-');
    const addPob = word => `${word} (POB.)`;

    const spreadDashes = word => {
      const parts = word.split(/\s+/);
      const results = new Set();

      for (let i = 0; i < parts.length; i++) {
        for (let j = i + 1; j <= parts.length; j++) {
          const slice = parts.slice(i, j).join(' ');
          results.add(slice);
          results.add(formatWithDashes(slice));
        }
      }

      return Array.from(results);
    };

    const generateCombinations = words => {
      const combinations = new Set();
      for (let i = 0; i < words.length; i++) {
        for (let j = i + 1; j <= words.length; j++) {
          const slicedWords = words.slice(i, j).join(' ');

          combinations.add(slicedWords);
          combinations.add(formatWithDashes(slicedWords));

          const pobVersion = addPob(slicedWords);
          combinations.add(pobVersion);
          combinations.add(formatWithDashes(pobVersion));

          spreadDashes(slicedWords).forEach(combination =>
            combinations.add(combination)
          );
          spreadDashes(pobVersion).forEach(combination =>
            combinations.add(combination)
          );
        }
      }
      return Array.from(combinations);
    };

    const wordArray = remainingWords.flatMap(word => word.split(/\s+/));
    const combinations = generateCombinations(wordArray);

    let matchedBarangay = '';
    let remainingAfterBarangays = [...remainingWords];

    for (const barangay of barangays) {
      const normalizedBarangay = barangay
        .toUpperCase()
        .replace(/[\s\-()]/g, '');

      for (const combination of combinations) {
        const normalizedCombination = combination
          .toUpperCase()
          .replace(/[\s\-()]/g, '');

        if (normalizedBarangay === normalizedCombination) {
          matchedBarangay = barangay;
          break;
        }
      }
      if (matchedBarangay) break;
    }

    return {
      matchedBarangay,
      remainingAfterBarangays: remainingAfterBarangays.filter(
        text => text.length > 0
      )
    };
  };

  const { matchedBarangay, remainingAfterBarangays } = evaluateBarangay(
    delWords,
    barangays
  );

  const leftWords = updatedArray.map(item => {
    ZIP.forEach(number => {
      item = item.replace(number, '').trim();
    });
    return item;
  });

  const wholedAdderss = [matchedProvinces, matchedCities, matchedBarangay]
    .join(', ')
    .replace(/-/g, ' ');
  const streetAddress = leftWords.join(',');

  function cleanStreetAddress(wholedAdderss, streetAddress) {
    wholedAdderss = wholedAdderss.replace(/-/g, ' ');

    const addressParts = wholedAdderss.split(',').map(part => part.trim());
    let streetParts = streetAddress.split(',').map(part => part.trim());

    streetParts = streetParts.filter(part => !addressParts.includes(part));

    streetParts = streetParts.map(part =>
      part.replace(/\(POB\.\)|\(pob\.\)/gi, '').trim()
    );

    return streetParts.join(', ');
  }

  const streets = cleanStreetAddress(wholedAdderss, streetAddress);

  const zip = String(ZIP);
  const prov = [...new Set(String(matchedProvinces).split(','))].join(',');
  const city = [...new Set(String(matchedCities).split(','))].join(',');
  const barangay = [...new Set(String(matchedBarangay).split(','))].join(',');
  const street = String(streets);

  return { prov, city, barangay, zip, street };
};

export default useAddress;
